<template>
  <div class="TitleImagePublic">
    <NavigationBar
      :title="configDetail.title"
      v-show="is_navbar === 'true'"
    ></NavigationBar>
    <div class="image-container">
      <img
        :src="item"
        alt=""
        srcset=""
        class="casesImg"
        v-for="(item, index) in configDetail.img"
        :key="index"
      />
    </div>
  </div>
</template>

<script>
import { getConfig } from "@/api/security";
import { NavigationBar } from "@/components";
export default {
  name: "TitleImagePublic",
  data() {
    return {
      key: "fraud_prevention_guidelines",
      is_navbar: "true",
      configDetail: {
        key: "fraud_prevention_guidelines",
        title: "防骗指南",
        img: [],
      },
    };
  },
  components: {
    NavigationBar,
  },
  mounted() {
    if (JSON.stringify(this.$route.query) != "{}") {
      this.key = this.$route.query.key;
      this.is_navbar = this.$route.query.is_navbar || "true";
    }
    let config = {
      key: "title_image_public",
    };
    this.requestGetConfig(config);
  },
  methods: {
    async requestGetConfig(config) {
      try {
        const result = await getConfig(config);
        if (result.code === 20000 || result.status_code === 1000) {
          let content = JSON.parse(result.data.content);
          let configDetail = content.find((item) => {
            return item.key === this.key;
          });
          if (configDetail) {
            this.configDetail = configDetail;
          } else {
            this.configDetail = {};
          }
        } else {
          this.$toast.fail(result.message);
        }
      } catch (error) {
        this.$toast.fail("请求失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.image-container {
  font-size: 0;
  .casesImg {
    width: 100%;
    display: block;
    margin: 0;
  }
}
</style>
